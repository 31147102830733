import React from "react"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import Text from "../components/Text"
import SEO from "../components/SEO"

const Privacy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Section className="mt-32">
        <Text tag="h1" styleTo="h1" className="mb-6">
          Privacy Policy
        </Text>
        <Text className="mb-6">
          We are committed to the privacy and confidentiality of information
          provided to us. This Privacy Statement provides information to help
          you understand how we collect, use and share your personal information
          and our privacy practices about any personal information collected by
          us from you directly and through the Platforms or the use of our
          products and services.
        </Text>
        <div className="mb-6">
          <Text className="mb-4 font-bold">1. Consent and removal. </Text>
          <Text>
            We process your data if you have consented to the processing
            activity. You may revoke your consent at any time. Doing so will bar
            us from further processing of your data based on your consent but
            will not impact the lawfulness of processing based on your consent
            before it was withdrawn.{" "}
          </Text>
        </div>
        <div className="mb-6">
          <Text className="mb-4 font-bold">2. Information we collect.</Text>
          <Text className="mb-4">
            This is information about you that you give us directly when you
            interact with us. You may provide it to us by filling out a form on
            our Site, corresponding with us by phone, e-mail, or at an in-person
            event. Depending on how you interact with us, the personal
            information we collect may include but without limitation:
          </Text>
          <ul className="grid gap-2 list-disc">
            <li>Browser usage</li>
            <li>Browser type </li>
            <li>Personal information where entered </li>
            <li>IP Address or any information relating to our website. </li>
          </ul>
        </div>
        <div>
          <Text className="mb-4 font-bold">3. Using your information.</Text>
          <Text className="mb-4">In short.</Text>
          <ol className="grid gap-2 list-decimal">
            <li>
              To personalize your experience (your information helps us to
              better respond to your individual needs){" "}
            </li>
            <li>
              To improve our website (we continually strive to improve our
              website offerings based on the information and feedback we receive
              from you){" "}
            </li>
            <li>
              To improve customer service (your information helps us to respond
              to your customer service requests, and support needs more
              effectively)
            </li>
            <li>
              To process transactions, Your information, whether public or
              private, will not be sold, exchanged, transferred, or given to any
              other company for any reason whatsoever, without your consent,
              other than for the express purpose of delivering the purchased
              product or service requested.
            </li>
          </ol>
        </div>
      </Section>
    </Layout>
  )
}

export default Privacy
